var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { ref: "sidebar", attrs: { cols: "3" } },
            [
              _c("div", {
                directives: [
                  {
                    name: "intersect",
                    rawName: "v-intersect",
                    value: _vm.intersectionObserver,
                    expression: "intersectionObserver",
                  },
                ],
              }),
              _c(
                "v-card",
                {
                  class: _vm.isIntersecting ? "" : "types-fixed",
                  style: { width: _vm.sidebarWidth },
                },
                [
                  _c("v-card-title", [_vm._v("Resources")]),
                  _c(
                    "v-card-text",
                    [
                      _vm.fetchingChartData.credit_by_sublevels
                        ? _c("dashboard-loader")
                        : _vm._e(),
                      [
                        _vm.availableResourceTypes.length > 0
                          ? _vm._l(
                              _vm.availableResourceTypes,
                              function ({
                                label,
                                value,
                                description,
                                color,
                                icon,
                              }) {
                                return _c(
                                  "v-card",
                                  {
                                    key: value,
                                    attrs: {
                                      flat: "",
                                      color: "grey lighten-3 mb-2",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggle(value)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      {
                                        staticClass: "py-0",
                                        attrs: {
                                          "two-line": "",
                                          color: "transparent",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            _c(
                                              "v-list-item-avatar",
                                              [
                                                _vm.selectedResourceTypes.indexOf(
                                                  value
                                                ) !== -1
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        style: `background-color: ${color}`,
                                                        attrs: { dark: "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(icon) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "grey lighten-1",
                                                        attrs: { dark: "" },
                                                      },
                                                      [_vm._v(_vm._s(icon))]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(label)),
                                                ]),
                                                _c("v-list-item-subtitle", [
                                                  _vm._v(_vm._s(description)),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _c(
                              "dashboard-empty",
                              { attrs: { icon: "mdi-format-list-bulleted" } },
                              [_vm._v("No resources")]
                            ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "9" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", xl: "5" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { height: "100%" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pt-2" },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "list-item pa-0" },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-developer-board"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "text-h6 secondary--text list-title",
                                        },
                                        [_vm._v("Credit utilization")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "list-subtitle" },
                                        [_vm._v("by resources")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "flex-grow-1 text-center" },
                            [
                              _vm.fetchingChartData.credit_by_sublevels
                                ? _c("dashboard-loader")
                                : [
                                    _vm.hasData("credit_by_sublevels") &&
                                    !_vm.isError("credit_by_sublevels")
                                      ? [
                                          _c("div", [_vm._v("A total of")]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-h4 secondary--text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.totalCreditsByResources
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "pb-8" }, [
                                            _vm._v(
                                              "AC spent on selected resources"
                                            ),
                                          ]),
                                          _c("doughnutChart", {
                                            attrs: {
                                              chartData: _vm.creditByResource,
                                              aggregateValue: "12 NCU",
                                              items: _vm.activeLabels,
                                            },
                                            on: {
                                              legendClick:
                                                _vm.handleLegendClickResource,
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                    !_vm.hasData("credit_by_sublevels")
                                      ? _c("dashboard-empty")
                                      : _vm._e(),
                                    _vm.isError("credit_by_sublevels")
                                      ? _c("dashboard-error")
                                      : _vm._e(),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-col", {
                    staticClass: "d-none d-xl-block",
                    attrs: { xl: "1" },
                  }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", xl: "5" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { height: "100%" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pt-2" },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "list-item pa-0" },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("mdi-file-tree-outline"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "text-h6 secondary--text list-title",
                                        },
                                        [_vm._v("Credit utilization")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "list-subtitle" },
                                        [_vm._v("by sublevels")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            { staticClass: "flex-grow-1 text-center" },
                            [
                              _vm.fetchingChartData.credit_by_sublevels
                                ? _c("dashboard-loader")
                                : [
                                    _vm.hasData("credit_by_sublevels") &&
                                    !_vm.isError("credit_by_sublevels")
                                      ? [
                                          _c("div", [_vm._v("A total of")]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-h4 secondary--text",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.totalCreditsbySublevels
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "pb-8" }, [
                                            _vm._v(
                                              "AC spent by selected " +
                                                _vm._s(_vm.currentSublevel)
                                            ),
                                          ]),
                                          _c("doughnutChart", {
                                            attrs: {
                                              chartData: _vm.creditBySublevels,
                                              rpid: _vm.rpid,
                                            },
                                            on: {
                                              legendClick:
                                                _vm.handleLegendClickSublevel,
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                    !_vm.hasData("credit_by_sublevels")
                                      ? _c("dashboard-empty")
                                      : _vm._e(),
                                    _vm.isError("credit_by_sublevels")
                                      ? _c("dashboard-error")
                                      : _vm._e(),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-10" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", height: "100%" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "pt-2" },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "list-item pa-0" },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [
                                      _c("v-icon", { attrs: { large: "" } }, [
                                        _vm._v("payment"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass:
                                            "text-h6 secondary--text list-title",
                                        },
                                        [_vm._v("Credit utilization timeline")]
                                      ),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "list-subtitle" },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.creditUsageTimelineResolution
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _vm.fetchingChartData.credit_utilization
                                ? _c("dashboard-loader")
                                : [
                                    _vm.hasData("credit_utilization") &&
                                    !_vm.isError("credit_utilization")
                                      ? _c("bar-chart", {
                                          attrs: {
                                            chartData:
                                              _vm.creditUtilizationTimeLine,
                                            options: {
                                              stacked: true,
                                              yTitle: "AC",
                                            },
                                            items: _vm.activeLabels,
                                          },
                                          on: {
                                            legendClick:
                                              _vm.handleLegendClickResource,
                                          },
                                        })
                                      : _vm._e(),
                                    !_vm.hasData("credit_utilization")
                                      ? _c("dashboard-empty")
                                      : _vm._e(),
                                    _vm.isError("credit_utilization")
                                      ? _c("dashboard-error")
                                      : _vm._e(),
                                  ],
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }